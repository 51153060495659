const colors = {
  current: 'currentColor',
  white: '#FFF',

  primary: {
    default: '#145FF5',
    '100': '#F0F6FF',
    '200': '#CCE2FF',
    '300': '#99C4FF',
    '400': '#579EFF',
    '500': '#145FF5',
    '600': '#0044CC',
    '700': '#0037A3',
    '800': '#002270',
    '900': '#07193A',
  },
  success: {
    default: '#21CF99',
    '100': '#F2FDF9',
    '200': '#D4F7EC',
    '300': '#A8F0DA',
    '400': '#7DE8C7',
    '500': '#21CF99',
    '600': '#1FAD81',
    '700': '#17826F',
    '800': '#094E4A',
    '900': '#082B20',
  },
  error: {
    default: '#FF0000',
    '100': '#FFF1F1',
    '200': '#FFCCCC',
    '300': '#FF9999',
    '400': '#FF5757',
    '500': '#FF0000',
    '600': '#CC0000',
    '700': '#990000',
    '800': '#570000',
    '900': '#330000',
  },
  misc: {
    default: '#CC00FF',
    '100': '#FCF0FF',
    '200': '#F5CCFF',
    '300': '#EB99FF',
    '400': '#DD57FF',
    '500': '#CC00FF',
    '600': '#A300CC',
    '700': '#7A0099',
    '800': '#450057',
    '900': '#290033',
  },
  neutral: {
    default: '#778999',
    '100': '#F6F9FE',
    '200': '#E1E6ED',
    '300': '#C8D0DB',
    '400': '#A8B3C1',
    '500': '#778999',
    '600': '#526570',
    '700': '#40545F',
    '800': '#293846',
    '900': '#171F27',
  },
  orange: {
    default: '#FF8A00',
    '100': '#FFF8F0',
    '200': '#FFF8CC',
    '300': '#FFD099',
    '400': '#FFB257',
    '500': '#FF8A00',
    '600': '#CC6E00',
    '700': '#995300',
    '800': '#572F00',
    '900': '#331C00',
  },
  warning: {
    default: '#FFD600',
    '100': '#FFFCF0',
    '200': '#FFF7CC',
    '300': '#FFEF99',
    '400': '#FFE766',
    '500': '#FFD600',
    '600': '#DBB800',
    '700': '#A88D00',
    '800': '#574800',
    '900': '#332B00',
  },
} as const;

export default colors;
