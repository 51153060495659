import React, { createContext, Dispatch, useReducer } from 'react';
import { createReducer, useCreateUseContext } from 'utils/utils';
import { ActionType } from 'utils/typeHelpers';

const actions = {
  enableSearch: () =>
    ({
      type: 'TOGGLE_SEARCH',
      payload: { isSearchEnabled: true },
    } as const),
  disableSearch: () =>
    ({
      type: 'TOGGLE_SEARCH',
      payload: { isSearchEnabled: false },
    } as const),
};

type actionsTypes = ActionType<typeof actions>;

const initialState = {
  isSearchEnabled: false,
  enableSearch: (_fn?: (...args: any[]) => void) => {},
  disableSearch: (_fn?: (...args: any[]) => void) => {},
};

type SearchStateContextProps = typeof initialState;

export const SearchStateContext = createContext<SearchStateContextProps>(
  initialState
);
export const SearchDispatchContext = createContext<Dispatch<actionsTypes>>(
  () => {
    return;
  }
);

const reducerHandlers = {
  destructurePayload: (s: typeof initialState, { payload }: actionsTypes) => ({
    ...s,
    ...payload,
  }),
};

const reducer = createReducer(initialState, actions, {
  TOGGLE_SEARCH: reducerHandlers.destructurePayload,
});

const SearchContextProvider = ({
  children,
}: {
  children: React.ReactChild;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const enableSearch = (fn?: () => void) => {
    dispatch(actions.enableSearch());
    if (typeof fn === 'function') fn();
  };

  const disableSearch = (fn?: () => void) => {
    dispatch(actions.disableSearch());
    if (typeof fn === 'function') fn();
  };

  const props = { enableSearch, disableSearch };

  return (
    <SearchStateContext.Provider
      value={{
        ...(state as SearchStateContextProps),
        ...props,
      }}
    >
      <SearchDispatchContext.Provider value={dispatch}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchStateContext.Provider>
  );
};

export function withSearchContext<T>(WrappedComponent: React.ComponentType<T>) {
  return function <R extends T>(props: R) {
    return (
      <SearchContextProvider>
        <WrappedComponent {...(props as T)} />
      </SearchContextProvider>
    );
  };
}

export const useSearchContext = () =>
  useCreateUseContext(SearchStateContext, SearchDispatchContext);

export default SearchContextProvider;
