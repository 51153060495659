import React, { FC, useEffect, useState } from 'react';
import { Steps, Tooltip } from 'antd';
import Button from 'components/atoms/Button/Button';
import { StepperPropsType } from 'components/Stepper/inteface';
import { HTMLButton } from 'custom';

const { Step } = Steps;

const Stepper: FC<StepperPropsType> = (props) => {
  const {
    steps = [],
    initial = 0,
    current = 0,
    previousVisible = true,
    nextVisible = true,
    skipVisible = true,
    previousDisabled = false,
    nextDisabled = false,
    skipDisabled = false,
    nextTitle = '',
    previousTitle = '',
    nextTooltipTitle = '',
    onNext = () => {},
    onPrevious = () => {},
    onSkip = () => {},
    onFinal = () => {},
    bgFooter = '#f1f1f1',
    ...rest
  } = props;
  const [currentStep, setCurrentStep] = useState(current || initial);

  useEffect(() => {
    setCurrentStep(current);
  }, [current]);

  const handleNext = (event: HTMLButton) => {
    setCurrentStep((step: number) => step + 1);
    onNext({ event, currentStep, nextStep: currentStep + 1 });
  };

  const handlePrevious = (event: HTMLButton) => {
    setCurrentStep((step: number) => (step > 0 ? step + -1 : step));
    onPrevious({ event, currentStep, nextStep: currentStep - 1 });
  };

  const renderNextButton = () => (
    <Button
      disabled={nextDisabled}
      onClick={(event) => {
        currentStep === steps.length - 1
          ? onFinal({ event, currentStep })
          : handleNext(event);
      }}
      className="tw-w-auto tw-place-self-end"
    >
      {nextTitle ||
        `Next ${
          steps[currentStep + 1]?.title
            ? ': ' + steps[currentStep + 1]?.title
            : ''
        }`}
    </Button>
  );

  return (
    <div className="vgs-stepper tw-flex tw-flex-col tw-h-full">
      <div className="tw-p-8 tw-h-full">
        <Steps
          {...rest}
          current={currentStep}
          className="tw-px-12 tw-mb-4 vgs-stepper__steps tw-inset-x-0"
        >
          {steps.map((item) => (
            // @ts-ignore
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div id="steps-content">
          <div className="tw-h-full tw-overflow-auto">
            {steps[currentStep].content}
          </div>
        </div>
      </div>
      <div
        className="controls tw-grid tw-grid-cols-3 tw-font-hairline tw-py-4 tw-px-8 tw-absolute tw-inset-x-0 tw-bottom-0"
        style={{ backgroundColor: bgFooter }}
      >
        {previousVisible ? (
          <Button
            type="link"
            disabled={previousDisabled}
            onClick={handlePrevious}
            className="tw-w-auto tw-place-self-start"
          >
            {previousTitle || 'Previous'}
          </Button>
        ) : (
          <div className="tw-w-auto" />
        )}
        {skipVisible ? (
          <Button
            disabled={skipDisabled}
            onClick={(event) => onSkip({ event, currentStep })}
            type="link"
            className="tw-w-auto tw-place-self-center"
          >
            Skip Guide
          </Button>
        ) : (
          <div className="tw-w-auto"></div>
        )}
        {nextVisible ? (
          nextDisabled && nextTooltipTitle ? (
            <Tooltip
              className="tw-w-auto tw-place-self-end"
              title={nextTooltipTitle}
            >
              <div>{renderNextButton()}</div>
            </Tooltip>
          ) : (
            renderNextButton()
          )
        ) : (
          <div className="tw-w-auto"></div>
        )}
      </div>
    </div>
  );
};

export default Stepper;
