import React, { FC, createContext, useEffect, useState } from 'react';

import { GoogleTagManager, IGoogleTagManagerConfig } from '../services';

type GTMContext = IGoogleTagManagerConfig & {
  loaded: boolean;
  setLoaded: (isLoaded: boolean) => void;
};

const GoogleTagManagerContext = createContext<GTMContext>({
  gtmContainerId: '',
  gtmEnvironment: '',
  gtmAuth: '',
  loaded: false,
  setLoaded: () => {},
});

const useGoogleTagManager = () => {
  return {
    pushEvent: GoogleTagManager.track,
  };
};

export const GoogleTagManagerProvider: FC<{
  gtmConfig: IGoogleTagManagerConfig;
}> = (props) => {
  const { children, gtmConfig } = props;
  const [loaded, setLoaded] = useState(GoogleTagManager.loaded);

  useEffect(() => {
    const handleOnLoad = () => setLoaded(true);

    GoogleTagManager.init(config, handleOnLoad);
  }, []);

  const config = {
    ...gtmConfig,
    loaded,
    setLoaded,
  };

  return (
    <GoogleTagManagerContext.Provider value={config}>
      {children}
    </GoogleTagManagerContext.Provider>
  );
};

export default useGoogleTagManager;
