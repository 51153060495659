import React, { CSSProperties, FC, Fragment } from 'react';
import { Card as AntdCard } from 'antd';
import { CardProps } from 'antd/lib/card';
import cn from 'classnames';

export interface ITileProps extends CardProps {
  active?: boolean;
  className?: string;
  style?: CSSProperties;
}

const { Meta } = AntdCard;

const Tile: FC<ITileProps> = (props) => {
  const { active, className, children, title, ...rest } = props;

  const content = <Fragment>{children}</Fragment>;

  return (
    <AntdCard
      className={cn('tile tw-py-4 tw-px-3 tw-rounded-lg', className, {
        active,
      })}
      hoverable
      {...rest}
    >
      <Meta title={title} description={content} />
    </AntdCard>
  );
};

export default Tile;
