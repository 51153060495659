import cn from 'classnames';
import React, {
  useLayoutEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import ReactDOM from 'react-dom';
import HubspotForm from 'react-hubspot-form';
import { noop } from 'lodash';
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons';

interface IFormConfigItem {
  fieldName: string;
  value?: unknown;
}

interface IFeedbackFormProps {
  formConfig: {
    [key: string]: IFormConfigItem;
  };
  hubspotConfig: {
    portalId: string;
    formId: string;
  };
  yesNoFeedback?: boolean;
  withGeoTagging?: boolean;
  withGeoInfo?: boolean;
  className?: string;
  onClick?: VoidFunction;
  isPreventSubmit?: boolean;
}

type FeedbackFormRef = {
  submitForm: () => void;
};

export type HubspotPersonalizationFormRef = React.ElementRef<
  typeof FeedbackForm
>;

const FeedbackForm = forwardRef<FeedbackFormRef, IFeedbackFormProps>(
  (props, ref) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [yesButton, setYesButton] = useState<HTMLElement | null>(null);
    const [noButton, setNoButton] = useState<HTMLElement | null>(null);
    const [hsTextarea, setHsTextarea] = useState<HTMLElement | null>(null);
    const [isYesHovered, setIsYesHovered] = useState(false);
    const [isNoHovered, setIsNoHovered] = useState(false);
    const [isYesClicked, setIsYesClicked] = useState(false);
    const [isNoClicked, setIsNoClicked] = useState(false);
    const [isDataSent, setDataSent] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [inputSources, setInputSources] = useState<{
      [key: string]: HTMLInputElement | null;
    }>(
      Object.keys(props.formConfig).reduce((acc, item) => {
        const inputSourcesHash: { [key: string]: any } = acc;
        inputSourcesHash[item] = '';
        return inputSourcesHash;
      }, {})
    );
    const [submitButton, setSubmitButton] = useState<HTMLElement | null>(null);

    const handleYesClick = () => {
      setIsYesClicked(true);
      setIsNoClicked(false);
      if (submitButton) {
        submitButton.click();
      }
    };

    const handleNoClick = () => {
      setIsYesClicked(false);
      setIsNoClicked(true);
      if (submitButton && hsTextarea) {
        hsTextarea.style.display = 'block';
        submitButton.style.display = 'block';
      }
    };

    useLayoutEffect(() => {
      const inputControls = Object.keys(props.formConfig).reduce(
        (acc: { [key: string]: HTMLInputElement | null }, item) => {
          const inputControlsAcc = acc;
          inputControlsAcc[item] = document.querySelector(
            `input[name="${props.formConfig[item].fieldName}"]`
          );
          return inputControlsAcc;
        },
        {}
      );

      const yesButtonContainer: HTMLElement | null = document.querySelector(
        '.feedback-hubspot-form input[value="yes"][type="radio"] + span'
      );
      const noButtonContainer: HTMLElement | null = document.querySelector(
        '.feedback-hubspot-form input[value="no"][type="radio"] + span'
      );
      const textareaContainer: HTMLElement | null = document.querySelector(
        '.feedback-hubspot-form .hs_multi_line_text div.input'
      );

      const submitButtonControl = document.querySelector(
        `.hsForm_${props.hubspotConfig.formId} .hs_submit input[type="submit"]`
      ) as HTMLElement;

      const yesNoPredicate =
        !props.yesNoFeedback ||
        (props.yesNoFeedback &&
          yesButtonContainer &&
          noButtonContainer &&
          textareaContainer);

      if (
        Object.keys(inputControls).every((key) => inputControls[key]) &&
        submitButtonControl &&
        yesNoPredicate
      ) {
        setInputSources(inputControls);
        setSubmitButton(submitButtonControl);

        if (props.yesNoFeedback) {
          setYesButton(yesButtonContainer);
          setNoButton(noButtonContainer);
          setHsTextarea(textareaContainer);
        }

        setIsVisible(true);
      }
    }, [isLoading]);

    useLayoutEffect(() => {
      type GlobalWindow = Window & {
        jQuery: {
          change: VoidFunction;
          trigger: VoidFunction;
          serializeArray: () => void;
        };
      };
      // TODO: my apologize for that
      const globalWindow = (window as unknown) as GlobalWindow;
      if (globalWindow) {
        globalWindow.jQuery =
          globalWindow.jQuery ||
          (() => ({
            change: noop,
            trigger: noop,
            serializeArray: noop,
          }));
      }
    });

    useImperativeHandle(ref, () => ({
      submitForm() {
        if (submitButton && !props?.isPreventSubmit) {
          submitButton.click();
        }
        if (props.onClick) {
          props.onClick();
        }
      },
    }));

    useLayoutEffect(() => {
      Object.keys(inputSources).forEach((key: string) => {
        if (inputSources[key]) {
          (inputSources[key] as HTMLInputElement).value = (Array.isArray(
            props.formConfig[key].value
          )
            ? (props.formConfig[key].value as string[]).join('; ')
            : props.formConfig[key].value) as string;
        }
      });
    }, [
      ...Object.values(props.formConfig).map((item) => item.value),
      ...Object.values(inputSources),
    ]);

    useLayoutEffect(() => {
      const handleSubmitForm = () => {
        setDataSent(true);
      };

      if (yesButton && noButton) {
        ReactDOM.render(
          <div
            onClick={handleYesClick}
            onMouseEnter={() => setIsYesHovered(true)}
            onMouseLeave={() => setIsYesHovered(false)}
          >
            <LikeOutlined
              className="icon-like"
              style={{ color: isYesClicked || isYesHovered ? '#2EB091' : '' }}
            />
          </div>,
          yesButton
        );
        ReactDOM.render(
          <div
            onClick={handleNoClick}
            onMouseEnter={() => setIsNoHovered(true)}
            onMouseLeave={() => setIsNoHovered(false)}
          >
            <DislikeOutlined
              className="icon-dislike"
              style={{ color: isNoClicked || isNoHovered ? '#E74C3C' : '' }}
            />
          </div>,
          noButton
        );

        submitButton?.addEventListener('click', handleSubmitForm);
      }

      return () => {
        submitButton?.removeEventListener('click', handleSubmitForm);
      };
    }, [
      yesButton,
      noButton,
      isYesClicked,
      isNoClicked,
      isYesHovered,
      isNoHovered,
    ]);

    return (
      <div
        className={cn([
          'feedback-form feedback-hubspot-form m-b-30',
          props.className,
          {
            'feedback-hubspot-form__submit': isDataSent,
          },
        ])}
      >
        <HubspotForm
          className={cn({ 'feedback-hubspot-form--hidden': !isVisible })}
          portalId={props.hubspotConfig.portalId}
          formId={props.hubspotConfig.formId}
          onSubmit={() => {}}
          onReady={() => {
            setIsLoading(false);
          }}
        />
      </div>
    );
  }
);

export default FeedbackForm;
