import * as React from 'react';
import cn from 'classnames';
import { Button as AntdButton } from 'antd';
import { BaseButtonProps, ButtonSize } from 'antd/lib/button/button';
import Group from 'antd/lib/button/button-group';

export interface IButtonProps extends Omit<BaseButtonProps, 'type' | 'size'> {
  /** Color of button.
   *
   * @default primary
   */
  type?:
    | 'primary'
    | 'link'
    | 'ghost_blue'
    | 'ghost_gray'
    | 'tertiary'
    | 'error'
    | 'disabled';
  onClick?: React.MouseEventHandler<HTMLElement>;
  /** Redirect url of link button. */
  href?: string;
  /** Same as target attribute of a, works when href is specified. */
  target?: string;
  /** To disable button */
  disabled?: boolean;
  theme?: 'light' | 'dark';
  size?: ButtonSize | 'lg' | 'sm' | 'xl';
  htmlType?: string;
}

const Button = (props: IButtonProps) => {
  const {
    size = 'default',
    type = 'primary',
    theme = 'light',
    ghost = false,
    className,
    ...rest
  } = props;

  const getSize = () => {
    if (['sm', 'small'].includes(size)) return 'small';
    if (['lg', 'large'].includes(size)) return 'large';
    return 'default';
  };

  const getType = () => {
    let buttonType = type;

    if (ghost) {
      buttonType = 'ghost_blue';
    }

    return theme === 'dark' ? `${buttonType}_dark` : buttonType;
  };

  return (
    <AntdButton
      {...rest}
      className={cn(className, { 'ant-btn-xl': size === 'xl' })}
      // @ts-ignore
      type={getType()}
      size={getSize()}
    />
  );
};

Button.Group = Group;

export default Button;
