import React, {
  FC,
  createContext,
  useEffect,
  useContext,
  useState,
} from 'react';

import { HeapAnalytics, ON_HEAP_LOADED } from '../services';

const INITIAL_HEAP_ANALYTICS = {
  track: () => {},
  identify: () => {},
  resetIdentity: () => {},
  addUserProperties: () => {},
  addEventProperties: () => {},
  removeEventProperty: () => {},
  clearEventProperties: () => {},
  appid: '',
  userId: '',
  identity: null,
  config: {},
  loaded: false,
};

const HeapAnalyticsContext = createContext<Heap>(INITIAL_HEAP_ANALYTICS);

const useHeapAnalytics = () => {
  return useContext(HeapAnalyticsContext);
};

export const HeapAnalyticsProvider: FC<{ heapTrackingId: string }> = ({
  children,
  heapTrackingId,
}) => {
  const [heapAnalytics, setHeapAnalytics] = useState<Heap>(
    INITIAL_HEAP_ANALYTICS
  );

  useEffect(() => {
    HeapAnalytics.init(heapTrackingId);
  }, [heapTrackingId]);

  useEffect(() => {
    const onHeapLoaded = () =>
      setTimeout(() => setHeapAnalytics(HeapAnalytics.instance), 0);

    window.addEventListener(ON_HEAP_LOADED, onHeapLoaded);

    return () => {
      window.removeEventListener(ON_HEAP_LOADED, onHeapLoaded);
    };
  }, []);

  return (
    <HeapAnalyticsContext.Provider value={heapAnalytics}>
      {children}
    </HeapAnalyticsContext.Provider>
  );
};

export default useHeapAnalytics;
