const GTM_BASE_URL = 'https://www.googletagmanager.com';
const GTM_START = 'gtm.start';
const GTM_SCRIPT = 'gtm.js';

export interface IGoogleTagManagerConfig {
  gtmContainerId: string;
  gtmEnvironment: string;
  gtmAuth: string;
}

class GoogleTagManager {
  _loaded = false;
  _pending = false;

  constructor() {
    window.dataLayer = window.dataLayer || [];
  }

  init(config: IGoogleTagManagerConfig, onLoad?: () => void) {
    const handleOnLoad = () => {
      this._loaded = true;
      this._pending = false;

      onLoad && onLoad();
    };

    if (!this._loaded && !this._pending) {
      this._pending = true;

      window.dataLayer.push({
        [GTM_START]: new Date().getTime(),
        event: GTM_SCRIPT,
      });

      loadGTMScript(config, handleOnLoad);
      loadGTMNoScript(config);
    }
  }

  get loaded() {
    return this._loaded;
  }

  track(event: { [key: string]: string | null }) {
    window.dataLayer.push(event);
  }

  get pending() {
    return this._pending;
  }
}

export default new GoogleTagManager();

const loadGTMScript = (config: IGoogleTagManagerConfig, onLoad: () => void) => {
  const script = document.createElement('script');
  const gtmParams = getGTMParams(config);

  script.src = `${GTM_BASE_URL}/${GTM_SCRIPT}?${gtmParams}`;
  script.async = true;
  script.onload = onLoad;

  document.head.insertBefore(script, document.head.childNodes[0]);
};

const loadGTMNoScript = (config: IGoogleTagManagerConfig) => {
  const noScript = document.createElement('noscript');

  noScript.innerHTML = gtmNoScriptTemplate(config);

  document.body.insertBefore(noScript, document.body.childNodes[0]);
};

const getGTMParams = (config: IGoogleTagManagerConfig) => {
  const { gtmContainerId, gtmEnvironment, gtmAuth } = config;

  return `id=${gtmContainerId}&gtm_preview=${gtmEnvironment}&gtm_auth=${gtmAuth}`;
};

const gtmNoScriptTemplate = (config: IGoogleTagManagerConfig) => {
  const gtmParams = getGTMParams(config);

  return `<iframe src="${GTM_BASE_URL}/ns.html?${gtmParams}}" height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;
};
