import React, { FC } from 'react';
import { Card as AntdCard, Divider } from 'antd';
import { CardProps } from 'antd/lib/card';

import cn from 'classnames';

export interface ICardProps extends CardProps {
  /** Fill header with grayish color.
   *
   * @default false
   */
  headerFill?: boolean;
  footer?: React.ReactNode;
  className?: string;
  warningBackground?: boolean;
}

const Card: FC<ICardProps> = (props) => {
  const {
    headerFill = false,
    className,
    bordered = true,
    footer,
    children,
    warningBackground = false,
    ...rest
  } = props;

  return (
    <AntdCard
      {...rest}
      bordered={bordered}
      className={cn(className, 'card', { headerFill, warningBackground })}
    >
      <div className="ant-card-content">{children}</div>
      {footer && (
        <>
          <Divider className="tw-my-0" />
          <div className="ant-card-footer">{footer}</div>
        </>
      )}
    </AntdCard>
  );
};

export default Card;
