class HeapAnalytics {
  _heapTrackingId: string | null;
  _loadedScripts: { [key: string]: boolean };

  constructor() {
    this._heapTrackingId = null;
    this._loadedScripts = {};
  }

  init(heapTrackingId: string, onLoad?: () => void) {
    if (!heapTrackingId) {
      throw new Error('HeapAnalytics::heapTrackingId is missed');
    }

    const onHeapLoaded = () => {
      this._loadedScripts[heapTrackingId] = true;

      onLoad && onLoad();
    };

    if (!this._loadedScripts.hasOwnProperty(heapTrackingId)) {
      this._heapTrackingId = heapTrackingId;
      this._loadedScripts[heapTrackingId] = false;

      loadHeapAnalyticsScript(heapTrackingId);

      window.addEventListener(ON_HEAP_LOADED, onHeapLoaded);
    }
  }

  get isLoaded() {
    return (
      window.heap?.loaded ||
      (this._heapTrackingId && !!this._loadedScripts[this._heapTrackingId])
    );
  }

  get instance() {
    return window.heap;
  }

  identify(identity: string) {
    if (!this.isLoaded) {
      return;
    }

    window.heap.identify(identity);
  }

  track(eventName: string, eventOptions?: { [key: string]: string | number }) {
    if (!this.isLoaded) {
      return;
    }

    window.heap.track(eventName, eventOptions);
  }
}

export const ON_HEAP_LOADED = 'heap-loaded';

export default new HeapAnalytics();

const heapAnalyticsTemplate = (heapTrackingId: string) => {
  const onLoad = () => {
    const heapLoaded = new Event('heap-loaded');

    window.dispatchEvent(heapLoaded);
  };

  const onError = () => {
    throw new Error(`HeapAnalytics::failed to load script`);
  };

  return `
    window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.onload=${onLoad};r.onerror=${onError};r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
    heap.load("${heapTrackingId}");
  `;
};

const loadHeapAnalyticsScript = (heapTrackingId: string) => {
  const heapAnalyticsScript = document.createElement('script');
  const scriptContent = heapAnalyticsTemplate(heapTrackingId);

  heapAnalyticsScript.id = 'heap-analytics';
  heapAnalyticsScript.text = scriptContent;

  document.body.appendChild(heapAnalyticsScript);
};
