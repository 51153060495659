import React from 'react';
import { Icon, notification as antdNotification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';

export type NotificationTheme = 'success' | 'info' | 'error' | 'warning';

const DEFAULT_DURATION = 5;

const withIcon = (theme: NotificationTheme) => {
  const icons = {
    success: <Icon type="check-circle" theme="filled" />,
    info: <Icon type="info-circle" theme="filled" />,
    warning: <Icon type="warning" theme="filled" />,
    error: <Icon type="plus-circle" theme="filled" rotate={45} />,
  };

  return icons[theme];
};

const withTheme = (theme: NotificationTheme) => (args: ArgsProps) => {
  const className = `${args.className ?? ''} ${theme}`;

  return antdNotification[theme]({
    duration: DEFAULT_DURATION,
    icon: withIcon(theme),
    ...args,
    className,
  });
};

const notification = {
  ...antdNotification,
  success: withTheme('success'),
  info: withTheme('info'),
  error: withTheme('error'),
  warning: withTheme('warning'),
};

export default notification;
