import colors from 'styles/colors';

const baseStyles = {
  diffContainer: {
    backgroundColor: colors.neutral['100'],
    color: colors.neutral['700'],
    borderRadius: '8px',
    'tbody tr:first-child td': {
      paddingTop: '0.5em',
    },
    'tbody tr:last-child td': {
      paddingBottom: '0.5em',
    },
    pre: {
      lineHeight: 'inherit',
      fontSize: '0.85rem',
      display: 'inline',
    },
    overflow: 'hidden',
  },
  line: {
    border: 'none !important',
    '&:first-child td': {
      paddingTop: '0.5em',
    },
    '&:last-child td': {
      paddingBottom: '0.5em',
    },
    'td:nth-child(3n)': {
      paddingRight: '1em',
    },
  },
  gutter: {
    backgroundColor: 'transparent',
    color: colors.neutral.default,
    padding: '0 10px',
    minWidth: 'initial',
    cursor: 'default',
    pointerEvents: 'none',
    '&:nth-child(2n)': {
      borderLeft: `1px solid ${colors.neutral['300']}`,
    },
    '&:nth-child(2n-1)': {
      paddingLeft: '0.5em',
    },
    pre: {
      whiteSpace: 'nowrap',
      display: 'inline',
      lineHeight: 'initial',
    },
    width: '3em',
  },
  wordDiff: {
    padding: '3px 0 1px',
  },
  diffRemoved: {
    backgroundColor: `rgba(${colors.error['300']}, 0.2)`,
  },
  diffAdded: {
    backgroundColor: `rgba(${colors.success.default}, 0.4)`,
  },
  wordRemoved: {
    lineHeight: 'inherit !important',
  },
  wordAdded: {
    lineHeight: 'inherit !important',
  },
  codeFold: {
    backgroundColor: colors.neutral['100'],
    height: 'inherit',
    a: {
      color: 'inherit',
      opacity: '0.5',
      '&:hover': {
        opacity: '1',
      },
    },
    'td:nth-of-type(5), td:nth-of-type(6)': {
      display: 'none',
    },
    '> td:empty:not(:last-child)': {
      width: '2em',
    },
  },
  codeFoldGutter: {
    backgroundColor: 'inherit',
    width: '3em !important',
  },
  emptyLine: {
    paddingTop: '0',
    paddingBottom: '0',
  },
};

export const singleValueStyles = {
  ...baseStyles,
  marker: {
    display: 'none',
  },
  diffRemoved: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
};

export const styles = {
  ...baseStyles,
  variables: {
    removedBackground: `rgba(${colors.error['300']}, 0.2) !important`,
    wordRemovedBackground: `rgba(${colors.error['300']}, 0.2) !important`,
    addedBackground: `rgba(${colors.success.default}, 0.4) !important`,
    wordAddedBackground: `rgba(${colors.success.default}, 0.4) !important`,
  },
  marker: {
    width: '1em',
    padding: '0',
  },
};
