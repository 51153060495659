import React, { FC, useCallback } from 'react';
import { PopoverProps } from 'antd/lib/popover';
import cn from 'classnames';
import { Popover as AntPopover, Menu as AntMenu } from 'components/antd';

import {
  AVAILABLE_SERVICES_DESCRIPTION,
  AvailableService,
} from './available-services';

export interface IApplicationSwitcherProps extends PopoverProps {
  variant?: AvailableService;
  urls?: {
    [key in AvailableService]?: string;
  };
}

const ApplicationSwitcher: FC<IApplicationSwitcherProps> = (props) => {
  const { variant, urls = {}, children, ...rest } = props;
  const servicesUrls = Object.keys(urls);

  const handleRedirect = useCallback(
    (service: AvailableService) => () => {
      const redirectTo = urls[service];

      if (redirectTo && variant !== service) {
        window.location.assign(redirectTo);
      }
    },
    [urls, variant]
  );

  const content = (
    <AntMenu>
      {Object.keys(AVAILABLE_SERVICES_DESCRIPTION).map((service) => {
        if (!servicesUrls.includes(service)) {
          return null;
        }

        const { label, description, icon: Icon } =
          AVAILABLE_SERVICES_DESCRIPTION[service as AvailableService] || {};

        const isDisabled = variant === service;

        return (
          <AntMenu.Item
            key={service}
            onClick={handleRedirect(service as AvailableService)}
            className={cn({ disabled: isDisabled })}
          >
            <Icon className="vgs-application-switcher-icon tw-mr-4" />
            <div className="tw-flex tw-flex-col">
              <h2 className="tw-subhead tw-leading-normal service-header">
                {label}
              </h2>
              <span className="tw-text-body-b2">{description}</span>
            </div>
          </AntMenu.Item>
        );
      })}
    </AntMenu>
  );

  return (
    <AntPopover
      overlayClassName="vgs-application-switcher"
      trigger="click"
      placement="bottomLeft"
      autoAdjustOverflow={false}
      content={content}
      {...rest}
    >
      {children}
    </AntPopover>
  );
};

export default ApplicationSwitcher;
